import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async createMedicalDevice (body) {
		const response = await _axios.post('medicaldevice', body);
		if (response.data.resFlag) {
			const userJson = response.data.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async medicaldevicesdata (body) {
		const response = await _axios.post('medicaldevicesdata', body);
		if (response.data.resFlag) {
			const userJson = response.data.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async getPossibleDevices () {
		const response = await _axios.get('medicaldevicetemplate/getpossibledevices');
		if (response.data.resFlag) {
			const userJson = response.data.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async getPossibleDevices2 () {
		const response = await _axios.get('medicaldevicetemplate/getpossibledevices');
		if (response.data.resFlag) {
			const userJson = response.data.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async editMedicalDevice (body) {
		const response = await _axios.put(`medicaldevice/${body.id}`, body);
		if (response.data.resFlag) {
			const userJson = response.data.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async deleteMedicalDevice (body) {
		const response = await _axios.delete(`medicaldevice/${body.id}`, { headers: { patientId: body.patientId } });
		if (response.data.resFlag) {
			const userJson = response.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async getMyMedicalDevices (id) {
		const response = await _axios.get('medicaldevice/getmedicaldevicesforpatient', { headers: { patientId: id } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getDeviceById (id) {
		const response = await _axios.get(`medicaldevice/${id}`);
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getMedicalDeviceTemplate () {
		const response = await _axios.get('medicaldevicetemplate');
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getMedicalDeviceTemplateId (id) {
		const response = await _axios.get('medicaldevicetemplate/getbydevicetype', { headers: { deviceType: id } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getDeviceTemplateByManufacturer (object) {
		const response = await _axios.get('medicaldevicetemplate/getbydevicetypeandmanifacturerid', { headers: { deviceType: object.deviceType, manifacturerId: object.manufacturer } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getDeviceTemplatesByType (id) {
		const response = await _axios.get('medicaldevicetemplate/getbydevicetemplatesbytype', { headers: { deviceType: id } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async updateIsManualMeasurementForMedicalDevice (body) {
			// Explicitly convert `false` to a string (if needed) for headers.
			const isManualMeasurement = `${!body.val.isManualMeasurement}`;

			const response = await _axios.put('medicaldevice/updateismanualmeasurementformedicaldevice',
				{ data: null },
				{
					headers: {
						patientId: body.patientId,
						deviceIdentifier: body.val.deviceIdentifier,
						isManualMeasurement: isManualMeasurement,
					},
				},
			);

			if (response.data.resFlag) {
				return response.data.data;
			}

			throw new UsersError('No meta data!');
	},
	async getDeviceTemplatesByTypeAndManufacturer (body) {
		return await _axios.get('medicaldevicetemplate/getbydevicetemplatesbytypeandmanufacturer', { headers: { deviceType: body.id, ManufacturerId: body.ManufacturerId } });
	},
	async editMedicalDeviceTemplate () {
		// const response = await _axios.put(`medicaldevicetemplate/${body.id}`, body)
		// if (response.data.resFlag) {
		//   const userJson = response.data.data
		//   return userJson
		// } else {
		//   throw new UsersError('Invalid id')
		// }
	},
	async getmedicaldevicebydeviceidentifier (id) {
		const response = await _axios.get('medicaldevice/getmedicaldevicebydeviceidentifier', { headers: { deviceIdentifier: id } }); // 'E3:F0:01:00:18:72'

		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getmedicaldevicesforpatientV2 (id) {
		const response = await _axios.get('medicaldevice/getmedicaldevicesforpatientV2', { headers: { patientId: id } });

		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getmeasurementsforpatientsforhospital (body) {
		const response = await _axios.get('medicaldevicesdata/getmeasurementsforpatientsforhospital', { headers: { hospitalId: body.hospitalId, byMonth: body.byMonth, byYear: body.byYear } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getdevicedefaultsforhospital (hospitalId) {
		const response = await _axios.get('medicaldevicesdata/getdevicedefaultsforhospital', { headers: { hospitalId: hospitalId } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async updateDeviceDefaultValuesJsonForMedicalDevice (body) {
		const response = await _axios.put('medicaldevice/updatedevicedefaultvaluesjsonformedicaldevice', { data: null }, { headers: { patientId: body.patientId, deviceIdentifier: body.deviceIdentifier, deviceDefaultValuesJson: body.deviceDefaultValuesJson } });

		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
};
