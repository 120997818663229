/* eslint-disable no-unused-vars */
import Repository from '../../repositories/RepositoryFactory';

const libphonenumber = require('libphonenumber-js');
const HospitalsRepository = Repository.get('hospitals');

export default {
	namespaced: true,
	state: {
		hospitals: null,
		detailedHospital: null,
		selectedHospital: null,
		assignedAdmins: null,
		users: null,
		patients: null,
		medicalStaff: null,
		relatedPatients: null,
		hospitalData: null,
		// hospitalAdmins: null,
		// selectedHospitalAdmin: null,
		supportMessage: null,
		getChatDetails: null,
		getSendMessage: null,
		getSendFileMessage: null,
	},
	mutations: {
		CLEAR_RELATED_PATIENTS (state) {
			state.relatedPatients = null;
		},
		GET_HOSPITALS (state, payload) {
			state.hospitals = payload.data;
		},
		SELECTED_HOSPITAL (state, payload) {
			state.selectedHospital = payload;
		},
		GET_HOSPITAL_BY_ID (state, payload) {
			state.detailedHospital = payload;
		},
		GET_RELATED_MEDICAL_STAFF_FOR_HOSPITAL (state, payload) {
			state.assignedAdmins = payload.data.usersRelatedToHospital?.filter(users => users.roleId === 6);
			state.users = payload.data.usersRelatedToHospital;
			state.patients = payload.data.usersRelatedToHospital?.filter(users => users.roleId === 5);
		},
		GET_RELATED_MEDICAL_STAFF (state, payload) {
			state.hospitalData = payload.data.hospitalData;
			state.medicalStaff = payload.data.usersRelatedToHospital;
		},
		GET_RELATED_PATIENTS (state, payload) {
			state.relatedPatients = payload.data.usersRelatedToHospital;
		},
		// GET_HOSPITAL_ADMINS (state, payload) {
		// 	state.hospitalAdmins = payload;
		// },
		// SELECTED_HOSPITAL_ADMIN (state, payload) {
		// 	state.selectedHospitalAdmin = payload;
		// },
		GET_SUPPORT_MESSAGES (state, payload) {
			state.supportMessage = payload;
		},
		GET_CHAT_DETAILS (state, payload) {
			state.getChatDetails = payload;
		},
		GET_SEND_MESSAGES (state, payload) {
			state.getSendMessage = payload;
		},
		GET_SEND_FILE_MESSAGES (state, payload) {
			state.getSendFileMessage = payload;
		},

	},
	actions: {
		async getHospitals ({ commit }) {
			commit('GET_HOSPITALS', await HospitalsRepository.getHospitals());
		},
		async getHospitalById ({ commit }, id) {
			commit('GET_HOSPITAL_BY_ID', await HospitalsRepository.getHospitalById(id));
		},
		// async getHospitalAdmins ({ commit }, id) {
		// 	commit('GET_HOSPITAL_ADMINS', await HospitalsRepository.getHospitalAdmins());
		// },
		// eslint-disable-next-line no-unused-vars
		async createHospital ({ commit }, body) {
			const res = await HospitalsRepository.createHospital(body);
			return res;
		},
		async getEcpMappingLocation ({ commit }, body) {
			const res = await HospitalsRepository.getEcpMappingLocation(body);
			return res;
		},
		async doAutomaticEcpUsersMapping ({ commit }, body) {
			const res = await HospitalsRepository.doAutomaticEcpUsersMapping(body);
			return res;
		},
		async doAutomaticEcpResidentsMapping ({ commit }, body) {
			const res = await HospitalsRepository.doAutomaticEcpResidentsMapping(body);
			return res;
		},
		async doFinalizeMeasurmentsAutomatically ({ commit }, body) {
			const res = await HospitalsRepository.doFinalizeMeasurmentsAutomatically(body);
			return res;
		},
		async editHospital ({ commit }, body) {
			const res = await HospitalsRepository.editHospital(body);
			return res;
		},
		async deleteHospital ({ commit }, id) {
			const res = await HospitalsRepository.deleteHospital(id);
			return res;
		},
		async assignUserToHospital ({ commit }, body) {
			// {
			// 	"HospitalId":1,
			// 	"UserId":853
			// }
			const res = await HospitalsRepository.assignUserToHospital(body);
			return res;
		},
		async deassignUserFromHospital ({ commit }, body) {
			// {
			// 	"HospitalId":1,
			// 	"UserId":853
			// }
			const res = await HospitalsRepository.deassignUserFromHospital(body);
			return res;
		},
		async assignAdminToHospital ({ commit }, body) {
			const res = await HospitalsRepository.assignAdminToHospital(body);
			return res;
		},
		async deassignAdminToHospital ({ commit }, body) {
			const res = await HospitalsRepository.deassignAdminToHospital(body);
			return res;
		},
		async assignPatientToHospital ({ commit }, body) {
			const res = await HospitalsRepository.assignPatientToHospital(body);
			return res;
		},
		async assignDoctorToHospital ({ commit }, body) {
			const res = await HospitalsRepository.assignDoctorToHospital(body);
			return res;
		},
		async getRelatedMedicalStaffForHospital ({ commit }, hospitalId) {
			commit('GET_RELATED_MEDICAL_STAFF_FOR_HOSPITAL', await HospitalsRepository.getRelatedMedicalStaffForHospital(hospitalId));
		},
		async supportmessage ({ commit }) {
			commit('GET_SUPPORT_MESSAGES', await HospitalsRepository.supportmessage());
		},
		async sendSupportMessage ({ commit }, body) {
			return await HospitalsRepository.sendSupportMessage(body);
		},
		async changeSupportMessageStatus ({ commit }, body) {
			return await HospitalsRepository.changeSupportMessageStatus(body);
		},
		async sendFileSupportMessage ({ commit }, body) {
			return await HospitalsRepository.sendFileSupportMessage(body);
		},
		async roomDetails ({ commit }, id) {
			commit('GET_CHAT_DETAILS', await HospitalsRepository.roomDetails(id));
		},
		async getRelatedPatients ({ commit }, hospitalId) {
			await commit('GET_RELATED_PATIENTS', await HospitalsRepository.getRelatedPatients(hospitalId));
		},
		async getRelatedMedicalStaff ({ commit }, hospitalId) {
			commit('GET_RELATED_MEDICAL_STAFF', await HospitalsRepository.getRelatedMedicalStaff(hospitalId));
		},
		async getRelatedPatientsv2 ({ commit }, hospitalId) {
			commit('GET_RELATED_PATIENTS', await HospitalsRepository.getRelatedPatientsv2(hospitalId));
		},
		async getRelatedPatientsV4 ({ commit }, hospitalId) {
			commit('GET_RELATED_PATIENTS', await HospitalsRepository.getRelatedPatientsV4(hospitalId));
		},
	},
	getters: {
		getMedicalStaff (state) {
			return state.medicalStaff;
		},
		getDoctorAndNurse (state) {
			return state.users?.filter(roleId => (roleId.roleId === 3 || roleId.roleId === 4) && roleId.status === 1);
		},
		getPatients (state) {
			return state.relatedPatients;
		},
		getSelectedHospital (state) {
			return state.selectedHospital;
		},
		// getSelectedHospitalAdmin (state) {
		// 	return state.selectedHospitalAdmin;
		// },
	},
};
